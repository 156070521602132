import React from 'react';
import { IconButton } from '@material-ui/core';
import { Grid } from '@material-ui/core'
import Autocomplete from '../../../common/AutoComplete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';
import Footer from '../../footer/Footer';
import DailogComp from '../../../common/Dialog';

const Home = () => {
  return (
    <>
    <div className="text-center mt-10">
      <h1 className="text-4xl font-bold">CheckInspect</h1>
      <h1 className="text-xl mt-2">For Beekeepers</h1>
      <p className="my-2 text-gray-500">Check what the weather conditons will be like for your next beehive inspection.</p>
      <Grid container justify="center">
          <Grid item xl={6} lg={6} md={6} className="bg-yellow-500 rounded p-10">
            <Autocomplete />
          </Grid>
      </Grid>

      <div className="mt-16 p-10">
        <Grid container justify="center">
            <Grid item xl={10} lg={10} md={10}>
                <Grid container justify="center" spacing={2}>
                  <Grid item lg={4}>
                    <h1 className="font-bold">Optimal Conditions</h1>
                    <CheckCircleIcon className="text-5xl text-green-600	 my-3" />
                    <p className="text-gray-800 text-xs font-semibold">Best weather conditions for an inspection. Aim for these times.</p>
                  </Grid>

                  <Grid item lg={4}>
                    <h1 className="font-bold">Viable Conditions</h1>
                    <CheckCircleIcon className="text-5xl my-3" />
                    <p className="text-gray-800 text-xs font-semibold">Not optimal, but can inspect if you must.</p>
                  </Grid>

                  <Grid item lg={4}>
                    <h1 className="font-bold">Inadvisable Conditions</h1>
                    <CancelIcon className="text-5xl my-3 text-red-600" />
                    <p className="text-gray-800 text-xs font-semibold">Not advisable due to possible adverse effects and damage to beehive health.</p>
                  </Grid>
                </Grid>
            </Grid>
        </Grid>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default Home;
