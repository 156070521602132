import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../style/theme'
import Appbar from './appbar/Appbar'

interface IProps {
    children: React.ReactNode;
    // any other props that come into the component
}

const Layout = ({ children, ...props }: IProps) => {
    return (
        <ThemeProvider theme={theme}>
            <Appbar />
            <div {...props}>{children}</div>
        </ThemeProvider>
    )
};
export default Layout;


