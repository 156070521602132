import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom';

function Footer() {
    return (
        <div className="footer py-4">
            <Container fixed>
                <Grid container>
                    <Grid item md={6}>
                        <Typography className="text-center">Copyright 2021 BeeKeepPal. All rights reserved.</Typography>
                    </Grid>

                    <Grid item md={6}>
                        <Typography className="text-center"><a target="_blank" rel="noreferrer" href="https://beekeeppal.com">Back to main site.</a></Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};
export default withRouter(Footer);
