import React from 'react';
import { Button, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector, useDispatch } from 'react-redux';
import { setLocation } from '../../../redux/actions';
import { userServices } from '../../../services/Services';
import { Cancel, CheckCircleOutline } from '@material-ui/icons';
import Footer from '../../footer/Footer';
import { withRouter } from 'react-router-dom';

interface State {
    data: any,
    selected: any
}

interface IProps {
    history: any;
    // any other props that come into the component
}

function ViewDay({ history, ...props }: IProps) {
    const dispatch = useDispatch()
    const stateR: any = useSelector(state => state);
    const [state, setstate] = React.useState<State>({
        data: null,
        selected: null
    });

    const search = window.location.search.slice(1).split("%20").join(" ")
    const searchSplit = search.split("?")
    const cityName = searchSplit[0]
    const seconds: any = searchSplit[1]
    const date = new Date(seconds * 1000)

    const onClickBtn = () => {
        history.push({ pathname: '/forecast', search: cityName })
    }

    // useEffect
    React.useEffect(() => {
        setstate({ ...state, data: "" })
        const cityName = window.location.search.slice(1).split("%20").join(" ")
        dispatch(setLocation(cityName))
        const endpoint = `/day?q=${cityName}&units=${stateR.states.unit}`
        userServices
            .getReq(endpoint)
            .then((val) => (
                setstate({ ...state, data: val })
            ))
    }, [stateR.states.unit])

    // days & months array
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return (
        <div>
            <Button variant="contained" onClick={onClickBtn} className="bg-yellow-500 hover:bg-yellow-600 font-bold m-2">Back to Week</Button>
            {!state.data && <>
                <Skeleton className="h-20 w-1/2 mx-auto" />
                <Skeleton className="h-16 w-1/4 mx-auto" />
            </>}
            {state.data && <>
                <Typography className="text-5xl font bold text-center">
                    {state.data && days[new Date(date).getDay()]}, {state.data && months[new Date(date).getMonth()]} {state.data && new Date(date).getDate()}</Typography>
                <Typography className="text-3xl font bold text-center pb-3">{cityName}</Typography>
            </>}

            {/* <div className="grid grid-cols-6 gap-4">
                <div className="col-start-2 col-span-4 text-center bg-blue-300 py-4 border border-black rounded">
                    <div className="grid grid-cols-3 gap-4">
                        <div>
                            {!state.data && <>
                                <Skeleton className="h-12 w-1/2 mx-auto" />
                                <Skeleton variant="circle" className="h-12 w-12 mx-auto" />
                            </>
                            }
                            {state.data && <>
                                <Typography className="font-bold text-xl px-auto">{state.data && state.data.list[0].weather[0].description}</Typography>
                                <img src={`http://openweathermap.org/img/wn/${state.data && state.data.list[0].weather[0].icon}@2x.png`} className="h-20 mx-auto" alt="weather" /></>
                            }

                        </div>

                        <div>
                            {stateR.states.unit === "metric" ?
                                // metric unit conditions
                                <>
                                    {state.data && state.data.list[0].main.temp <= 10 &&
                                        <><Cancel className="text-5xl text-red-600 mx-auto" /><Typography className="font-semibold text-lg mx-auto">Not Advisable</Typography></>
                                    }
                                    {state.data && state.data.list[0].main.temp >= 40 &&
                                        <><Cancel className="text-5xl text-red-600 mx-auto" /><Typography className="font-semibold text-lg mx-auto">Not Advisable</Typography></>
                                    }
                                    {state.data && state.data.list[0].main.temp > 10 && state.data && state.data.list[0].main.temp < 40 &&
                                        <>
                                            {state.data && state.data.list[0].weather[0].description === "broken clouds" || state.data && state.data.list[0].weather[0].description === "scattered clouds" || state.data && state.data.list[0].weather[0].description === "few clouds" || state.data && state.data.list[0].weather[0].description === "clear sky" || state.data && state.data.list[0].weather[0].description === "overcast clouds" || state.data && state.data.list[0].weather[0].description === "sky is clear"
                                                ?
                                                <>
                                                    <CheckCircleOutline className="text-5xl text-green-800 mx-auto" />
                                                    <Typography className="font-semibold text-lg mx-auto">Optimal</Typography>

                                                </>
                                                :
                                                <>
                                                    <Cancel className="text-5xl text-red-600 mx-auto" />
                                                    <Typography className="font-semibold text-lg mx-auto">Not Advisable</Typography>
                                                </>
                                            }
                                        </>
                                    }
                                    {state.data && state.data.list[0].wind.speed > 16 && state.data && state.data.list[0].main.temp < 24 && <Typography className="font-semibold text-lg mx-auto">A bit windy</Typography>}
                                    {state.data && state.data.list[0].wind.speed > 34 && <Typography className="font-semibold text-lg mx-auto">A bit windy</Typography>}

                                </>
                                :
                                // imperial unit conditions
                                <>
                                    {state.data && state.data.list[0].main.temp <= 50 &&
                                        <><Cancel className="text-5xl text-red-600 mx-auto" /><Typography className="font-semibold text-lg mx-auto">Not Advisable - Too cold</Typography></>
                                    }

                                    {state.data && state.data.list[0].main.temp >= 104 &&
                                        <><Cancel className="text-5xl text-red-600 mx-auto" /><Typography className="font-semibold text-lg mx-auto">Not Advisable - Too hot</Typography></>
                                    }
                                    {state.data && state.data.list[0].main.temp > 50 && state.data && state.data.list[0].main.temp < 104 &&
                                        <>
                                            {state.data && state.data.list[0].weather[0].description === "broken clouds" || state.data && state.data.list[0].weather[0].description === "scattered clouds" || state.data && state.data.list[0].weather[0].description === "few clouds" || state.data && state.data.list[0].weather[0].description === "clear sky" || state.data && state.data.list[0].weather[0].description === "overcast clouds" || state.data && state.data.list[0].weather[0].description === "sky is clear"
                                                ?
                                                <>
                                                    <CheckCircleOutline className="text-5xl text-green-800 mx-auto" />
                                                    <Typography className="font-semibold text-lg mx-auto">Optimal</Typography>
                                                </>
                                                :
                                                <>
                                                    <Cancel className="text-5xl text-red-600 mx-auto" />
                                                    <Typography className="font-semibold text-lg mx-auto">Not Advisable</Typography>
                                                </>
                                            }
                                        </>
                                    }
                                    {state.data && state.data.list[0].wind.speed > 13 && state.data && state.data.list[0].main.temp < 24 && <Typography className="font-semibold text-lg mx-auto">A bit windy</Typography>}
                                    {state.data && state.data.list[0].wind.speed > 24 && <Typography className="font-semibold text-lg mx-auto">Too windy</Typography>}
                                </>
                            }
                        </div>

                        <div>
                            {!state.data && <>
                                <Skeleton className="w-1/2 mx-auto" />
                                <Skeleton className="h-16" />
                                <Skeleton className="w-1/4 mx-auto" />
                                <Skeleton className="w-1/4 mx-auto" />
                            </>}
                            {state.data &&
                                <>
                                    <Typography className="font-bold text-xl">{state.data && state.data.list[0].main.temp_min}°
                            -
                                {state.data && state.data.list[0].main.temp_max}°</Typography>
                                    <Typography className="font-bold text-xl">Wind({stateR.states.unit === "metric" ? "KM/H" : "MPH"}): {state.data && state.data.list[0].wind.speed} - {state.data && state.data.list[0].wind.speed} </Typography>
                                    <Typography className="font-bold text-sm">Rain:  {state.data && state.data.list[0].rain !== undefined ? state.data.list[0].rain['3h'] : 0}{stateR.states.unit === "metric" ? "mm" : "in"}
                                    </Typography>
                                    {state.data.list[0].snow}
                                    <Typography className="font-bold text-sm">Snow: {state.data && state.data.list[0].snow !== undefined ? state.data.list[0].snow['3h'] : 0}{stateR.states.unit === "metric" ? "mm" : "in"}
                                    </Typography>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div> */}

            <Grid container justify="center">
                <Grid item md={8}>
                    <TableContainer component={Paper} className="my-4">
                        <Table>
                            <TableHead className="bg-yellow-500 font-bold">
                                <TableRow>
                                    <TableCell align="center" className="font-bold">Time</TableCell>
                                    <TableCell align="center" className="font-bold">Weather</TableCell>
                                    <TableCell align="center" className="font-bold">Inspect</TableCell>
                                    <TableCell align="center" className="font-bold">Temp</TableCell>
                                    <TableCell align="center" className="font-bold">Rain</TableCell>
                                    <TableCell align="center" className="font-bold">Wind</TableCell>
                                    <TableCell align="center" className="font-bold">Snow</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!state.data &&
                                    <TableRow>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                    </TableRow>}
                                {state.data && state.data.list.map((value: any) => (
                                    new Date(value.dt_txt).getDate() === date.getDate() &&
                                    <TableRow key={Math.random()}>
                                        <TableCell align="center">{new Date(value.dt_txt).getHours()}:00 </TableCell>
                                        <TableCell align="center">{value.weather[0].description}</TableCell>
                                        <TableCell align="center">{value.weather[0].description === "broken clouds" || value.weather[0].description === "scattered clouds" || value.weather[0].description === "few clouds" || value.weather[0].description === "clear sky" || value.weather[0].description === "overcast clouds" || value.weather[0].description === "sky is clear"
                                            ?
                                            <>
                                                <CheckCircleOutline className="text-2xl text-green-800 mx-auto" />
                                                <Typography>Optimal</Typography></>
                                            :
                                            <>
                                                <Cancel className="text-2xl text-red-600 mx-auto" />
                                                <Typography>Not Advisable</Typography></>
                                        }
                                        </TableCell>
                                        <TableCell align="center">{value.main.temp}{stateR.states.unit === "metric" ? "°C" : "°F"}</TableCell>
                                        <TableCell align="center">{value.rain !== undefined ? value.rain['3h'] : 0}{stateR.states.unit === "metric" ? "mm" : "in"}</TableCell>
                                        <TableCell align="center">{value.wind.speed}{stateR.states.unit === "metric" ? "KM/H" : "MPH"}</TableCell>
                                        <TableCell align="center">{value.snow !== undefined ? value.snow['3h'] : 0}{stateR.states.unit === "metric" ? "mm" : "in"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Footer />
        </div>
    )
};
export default withRouter(ViewDay);
