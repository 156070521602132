import axios from 'axios'
import qs from 'qs'

export const userServices = {
  services,
  getReq,
};

const Prefix = "https://api.beekeeppal.com/v1/weather";
//const Prefix = "http://localhost/beekeeppal/weather";

async function services(data, endpoint) {
  const { data: response } = await axios.post(
    `${Prefix}${endpoint}`, qs.stringify({
      ...data
    }));
  return response;
}

async function getReq(endpoint) {
  const { data: response } = await axios.get(
    `${Prefix}${endpoint}`);
  return response;
}

