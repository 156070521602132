import React from 'react'
import Footer from '../../footer/Footer'
import { Button, Grid, Box, Typography, Paper, Collapse } from '@material-ui/core'
import { Cancel, CheckCircleOutline, ExpandLess, ExpandMore } from '@material-ui/icons';
import { userServices } from '../../../services/Services';
import { useSelector, useDispatch } from 'react-redux';
import { setLocation } from '../../../redux/actions';
import Skeleton from '@material-ui/lab/Skeleton';
import { withRouter } from 'react-router-dom';

interface State {
    expand: boolean,
    data: any,
    selected: any
}
interface IProps {
    history: any;
    // any other props that come into the component
}

function Forecast({ history, ...props }: IProps) {
    const dispatch = useDispatch()
    const stateR: any = useSelector(state => state);
    const [state, setstate] = React.useState<State>({
        expand: false,
        data: null,
        selected: null,
    });

    const cityName = window.location.search.slice(1).split("%20").join(" ")

    const collapse = (value: any) => () => {
        setstate({ ...state, selected: state.selected === value ? null : value })
    }

    const onClickBtn = (prop: any) => () => {
        history.push({ pathname: '/view', search: cityName + "?" + prop.dt })
    }

    // react useEffect
    React.useEffect(() => {
        setstate({ ...state, data: "" })
        dispatch(setLocation(cityName))
        const endpoint = `/forecast?q=${cityName}&units=${stateR.states.unit}`
        userServices
            .getReq(endpoint)
            .then((val) => {
                setstate({ ...state, data: val })
            })
            .catch((err) =>
                alert("Not found. To make search more precise put the city name, comma, 2-letter country code (ISO3166)."))
    }, [cityName, stateR.states.unit])
    const filtered = state.data && state.data.list.slice(0, 10)

    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return (
        <div>
            <div className="py-2">
                <Typography className="text-5xl font bold text-center">Forecast for</Typography>

                {!state.data &&
                    <Skeleton className="h-20 w-1/4 mx-auto" />
                }

                {state.data &&
                    <Typography className="text-5xl font bold text-center pb-3">{window.location.search.slice(1).split("%20").join(" ")}</Typography>
                }

                <Grid container justify="center">
                    <Grid item md={6}>
                        {!state.data &&
                            <Box boxShadow=" 0px 0px 1px 0px rgba(50, 50, 50, 1)"
                                className="p-4">
                                <Skeleton className="h-28" animation="wave" />
                                <Skeleton className="h-28 mt-1" animation="wave" />
                                <Skeleton className="h-28 mt-1" animation="wave" />
                            </Box>
                        }
                        {state.data && filtered.map((value: any, index: number) => (

                            <div key={Math.random()}>
                                <Box onClick={collapse(value)} boxShadow=" 0px 0px 1px 0px rgba(50, 50, 50, 1)"
                                    className="p-4 hover:bg-blue-300 cursor-pointer w-full inline-flex relative justify-center items-center">
                                    <div className="grid grid-cols-6 gap-2">
                                        <div>
                                            <img src={`http://openweathermap.org/img/wn/${value.weather[0].icon}@2x.png`} alt="weather" className="bg-blue-300 border border-black rounded" />
                                        </div>

                                        <div className="col-span-2">
                                            <div className="mx-12 grid items-center">
                                                {stateR.states.unit === "metric" ?

                                                    // metric unit conditions
                                                    <>
                                                        {value.temp.day <= 10 &&
                                                            <><Cancel fontSize="large" className="text-red-600 mx-auto" /><span className="font-semibold text-lg mx-auto">Not Advisable - Too Cold</span></>
                                                        }

                                                        {value.temp.day >= 40 &&
                                                            <><Cancel fontSize="large" className="text-red-600 mx-auto" /><span className="font-semibold text-lg mx-auto">Not Advisable - Too Hot</span></>
                                                        }

                                                        {value.temp.day > 10 && value.temp.day < 40 &&
                                                            <>
                                                                {value.weather[0].description === "overcast clouds" || value.weather[0].description === "scattered clouds" || value.weather[0].description === "few clouds" || value.weather[0].description === "clear sky" || value.weather[0].description === "broken clouds" || value.weather[0].description === "sky is clear"
                                                                    ?
                                                                    <>
                                                                        <CheckCircleOutline fontSize="large" className="text-green-800 mx-auto" />
                                                                        <span className="font-semibold text-lg mx-auto">Optimal</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Cancel fontSize="large" className="text-red-600 mx-auto" />
                                                                        <span className="font-semibold text-lg mx-auto text-center">Not Advisable</span>
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                        {value.speed > 16 && value.temp.day < 24 && <span className="font-semibold text-lg mx-auto">A bit windy</span>}
                                                        {value.speed > 34 && <span className="font-semibold text-lg mx-auto">Too windy</span>}
                                                    </>

                                                    :
                                                    // imperial unit conditions
                                                    <>
                                                        {value.temp.day <= 50 &&
                                                            <><Cancel fontSize="large" className="text-red-600 mx-auto" /><span className="font-semibold text-lg mx-auto">Not Advisable - Too Cold</span></>
                                                        }

                                                        {value.temp.day >= 104 &&
                                                            <><Cancel fontSize="large" className="text-red-600 mx-auto" /><span className="font-semibold text-lg mx-auto">Not Advisable - Too HOt</span></>
                                                        }

                                                        {value.temp.day > 50 && value.temp.day < 104 &&
                                                            <>
                                                                {value.weather[0].description === "overcast clouds" || value.weather[0].description === "scattered clouds" || value.weather[0].description === "few clouds" || value.weather[0].description === "clear sky" || value.weather[0].description === "broken clouds" || value.weather[0].description === "sky is clear"
                                                                    ?
                                                                    <>
                                                                        <CheckCircleOutline fontSize="large" className="text-green-800 mx-auto" />
                                                                        <span className="font-semibold text-lg mx-auto">Optimal</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Cancel fontSize="large" className="text-red-600 mx-auto" />
                                                                        <span className="font-semibold text-lg mx-auto text-center">Not Advisable</span>
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                        {value.speed > 13 && value.temp.day < 24 && <span className="font-semibold text-lg mx-auto">A bit windy</span>}
                                                        {value.speed > 24 && <span className="font-semibold text-lg mx-auto">Too windy</span>}

                                                    </>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-span-2 weather-desc">
                                            <Typography className="text-center text-lg font-semibold">{days[new Date(value.dt * 1000).getDay()]}, {months[new Date(value.dt * 1000).getMonth()]} {new Date(value.dt * 1000).getDate()}</Typography>

                                            <Typography className="text-center text-lg font-semibold">{value.weather[0].description}</Typography>

                                            <Typography className="text-center text-lg font-semibold">{value.temp.min}°- {value.temp.max}°</Typography>
                                        </div>

                                        <div>
                                            <Button variant="contained"
                                                onClick={onClickBtn(value)}
                                                className="bg-yellow-500 pr-2 hover:bg-yellow-600 font-bold">
                                                View Day
                                            </Button>

                                            {state.selected === value ?
                                                <ExpandLess fontSize="large"
                                                    className="bg-blue-300 border border-black rounded-full mt-2 ml-8 "
                                                />
                                                :
                                                <ExpandMore fontSize="large"
                                                    className="bg-blue-300 border border-black rounded-full mt-2 ml-8 " />
                                            }
                                        </div>
                                    </div>
                                </Box>

                                <Collapse in={state.selected === value}>
                                    <Paper className="flex justify-center items-center py-4">
                                        <div className="mx-auto">
                                            <Typography className="font-bold text-md">Rain: {value.rain ? value.rain : 0}{stateR.states.unit === "metric" ? "mm" : "in"}</Typography>
                                            <Typography className="font-bold text-md">Snow: {value.snow ? value.snow : 0}{stateR.states.unit === "metric" ? "mm" : "in"}</Typography>
                                        </div>

                                        <Box bgcolor="#E5E5E5" mx="auto" py={1} px={1} borderRadius="10px">
                                            <Typography className="font-bold text-sm">Viable {new Date(value.dt * 1000).getHours()}hrs</Typography>
                                            <Typography className="text-sm">temp: <span>{value.temp.day}{stateR.states.unit === "metric" ? "°C" : "°F"}</span></Typography>
                                            <Typography className="text-sm">wind: <span>{value.speed}{stateR.states.unit === "metric" ? "KM/H" : "MPH"}</span></Typography>
                                            <Typography className="text-sm">humidity: {value.humidity}%</Typography>
                                        </Box>
                                    </Paper>
                                </Collapse>
                            </div>
                        ))}

                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    )
};
export default withRouter(Forecast);
