import React from 'react'
import {
  ButtonGroup, Typography, InputAdornment, IconButton, FormControlLabel, Radio,
  RadioGroup, DialogTitle, DialogContent, DialogActions, Dialog, TextField, Button
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { setUnit } from '../redux/actions';

export default function FormDialog() {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('imperial');
  const [temp, settemp] = React.useState({
    optimal: 1,
  })

  const handleClick = (prop: any) => () => {
    settemp({ ...temp, optimal: prop })
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
    dispatch(setUnit(event.target.value))
  };

  return (
    <div className="dialog-main">
      <IconButton color="secondary" onClick={handleClickOpen}>
        <Settings />
      </IconButton>

      <Dialog maxWidth="xs" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="py-2"><Typography className="font-bold text-center text-lg">Settings</Typography></DialogTitle>

        <DialogContent className="py-0">
          <RadioGroup className="justify-center" row aria-label="position" name="position" defaultValue="top">
            <FormControlLabel
              value="top"
              control={
                <Radio
                  checked={selectedValue === 'imperial'}
                  onChange={handleChange}
                  value="imperial"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'imperial' }}
                />
              }
              label="Imperial"
              labelPlacement="end"
            />

            <FormControlLabel
              value="top"
              control={<Radio
                checked={selectedValue === 'metric'}
                onChange={handleChange}
                value="metric"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'metric' }}
              />}
              label="Metric"
              labelPlacement="end"
            />
          </RadioGroup>

          <ButtonGroup className="my-2 mx-auto flex justify-center" color="primary" aria-label="outlined primary button group">
            <Button
              className={`${temp.optimal === 1 ? "bg-yellow-500 hover:bg-yellow-500 text-black" : ""}`}
              onClick={handleClick(1)}
              variant="outlined"
            >
              Optimal
            </Button>

            <Button
              className={`${temp.optimal === 2 ? "bg-yellow-500 hover:bg-yellow-500 text-black" : ""}`}
              onClick={handleClick(2)}
              variant="outlined"
            >
              Viable
            </Button>
          </ButtonGroup>

          <Typography className="text-center font-bold py-2">{temp.optimal === 1 ? "Optimal conditions:" : "Viable conditions:"}</Typography>

          <Typography className="text-center font-medium" gutterBottom>{temp.optimal === 1 ?
            "Best conditions to hold an inspection, use these days if possible." :
            "Conditions, which are not optimal, but an inspection could still occur if needed."}
          </Typography>

          <div className="grid grid-cols-6 gap-4">
            <div className="col-start-2 col-span-4">
              <TextField
                className="mb-4"
                id="outlined-number"
                label={`Minimum ${temp.optimal === 1 ? "Optimal" : "Viable"} Temp`}
                type="number"
                defaultValue={`${temp.optimal === 1 ? "50" : "59"}`}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{selectedValue === 'metric' ? "°C" : "°F"}
                  </InputAdornment>,
                }}
                variant="filled"
              />

              <TextField
                className="mb-4"
                id="outlined-number"
                label={`Maximum ${temp.optimal === 1 ? "Optimal" : "Viable"} Temp`}
                type="number"
                defaultValue="98.6"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{selectedValue === 'metric' ? "°C" : "°F"}
                  </InputAdornment>,
                }}
                variant="filled"
              />

              <TextField
                className="mb-4"
                id="outlined-number"
                defaultValue="0"
                label={`Maximum ${temp.optimal === 1 ? "Optimal" : "Viable"} Rain`}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{selectedValue === 'metric' ? "mm/3hr" : "inches/3hr"}
                  </InputAdornment>,
                }}
                variant="filled"
              />

              <TextField
                id="outlined-number"
                label={`Maximum ${temp.optimal === 1 ? "Optimal" : "Viable"} Wind`}
                type="number"
                defaultValue="2.25"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{selectedValue === 'metric' ? "KM/H" : "MPH"}
                  </InputAdornment>,
                }}
                variant="filled"
              />
            </div>
          </div>

        </DialogContent>

        <DialogActions className="justify-center">
          <Button color="primary" variant="contained" className="font-bold capitalize">
            Reset
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained" className="font-bold capitalize">
            Close
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}
