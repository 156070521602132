import React from 'react';
import { IconButton, AppBar, Fade, Toolbar, Typography } from '@material-ui/core'
import Autocomplete from '../../common/AutoComplete';
import DailogComp from '../../common/Dialog'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from 'react-router-dom';

interface IProps {
  history: any;
  // any other props that come into the component
}

function ButtonAppBar({ history, ...props }: IProps) {
  const [state, setstate] = React.useState({
    openSearch: false
  });

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Link to="/">
            <Typography variant="h6" className="text-lg font-bold" >
              <img className="logo" src="/logo.png" alt="BeeKeepPal CheckInspect" />
            </Typography>
          </Link>

          <div className="ml-auto">
            {
              state.openSearch ?
                <Fade in={state.openSearch}>
                  <Autocomplete />
                </Fade>
                :
                <IconButton onClick={() => setstate({ ...state, openSearch: true })} color="inherit"><SearchIcon /></IconButton>
            }
            <div className="inline-block"><DailogComp /></div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default withRouter(ButtonAppBar);
