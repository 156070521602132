import './style/App.scss';
import { Provider } from 'react-redux';
import { useStore } from './redux/store';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from './layout'
import Home from './layout/auth/home/Home'
import Forecast from './layout/auth/forecast/Forecast';
import ViewDay from '../src/layout/auth/viewDay/ViewDay'
import './index.css';
import { StylesProvider } from "@material-ui/core/styles";

const App = (): React.ReactElement => { 
  const store = useStore()  
  return (
    <Router>
      <StylesProvider injectFirst>
        <Provider store={store}>
          <Layout>
            <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/forecast">
            <Forecast />
          </Route>
          <Route path="/view">
            <ViewDay />
          </Route>
          </Switch>
          </Layout>
        </Provider>
      </StylesProvider>
    </Router>
  );
}

export default App;
